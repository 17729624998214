import type { SelectChangeEvent } from '@mui/material'
import { Box, MenuItem, Select, Stack, SvgIcon, Tooltip, Typography } from '@mui/material'
import GasStationIcon from '@/public/images/common/gas-station.svg'
import InfoIcon from '@/public/images/notifications/info.svg'
import css from './styles.module.css'
import chains from '@/config/chains'
import { useCurrentChain } from '@/hooks/useChains'
import type { RelayResponse } from '@/services/tx/relaying'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useEffect, useMemo, useState } from 'react'
import useWallet from '@/hooks/wallets/useWallet'
import { ethers } from 'ethers'
import { GAS_TANK_MODULE_ADDRESS } from '@/config/constants'
import { useWeb3 } from '@/hooks/wallets/web3'
import useSafeInfo from '@/hooks/useSafeInfo'
import { sameAddress } from '@/utils/addresses'

export const SPONSOR_LOGOS = {
  [chains.gno]: '/images/common/gnosis-chain-logo.png',
  [chains.gor]: '/images/common/token-placeholder.svg',
}

const SponsoredBy = ({ relays, tooltip }: { relays: RelayResponse; tooltip?: string }) => {
  const chain = useCurrentChain()

  return (
    <Box className={css.sponsoredBy}>
      <SvgIcon component={GasStationIcon} inheritViewBox className={css.icon} />
      <div>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography variant="body2" fontWeight={700} letterSpacing="0.1px">
            Sponsored by
          </Typography>
          <img src={SPONSOR_LOGOS[chain?.chainId || '']} alt={chain?.chainName} className={css.logo} />
          <Typography variant="body2" fontWeight={700} letterSpacing="0.1px">
            {chain?.chainName}
          </Typography>
          {tooltip ? (
            <Tooltip title={tooltip} placement="top" arrow>
              <span style={{ display: 'flex' }}>
                <SvgIcon
                  component={InfoIcon}
                  inheritViewBox
                  color="info"
                  fontSize="small"
                  sx={{ verticalAlign: 'middle', color: '#B2B5B2' }}
                />
              </span>
            </Tooltip>
          ) : null}
        </Stack>

        <Typography variant="body2" color="primary.light">
          Transactions per hour:{' '}
          <Box component="span" sx={{ fontWeight: '700', color: 'text.primary' }}>
            {relays.remaining} of {relays.limit}
          </Box>
        </Typography>
      </div>
    </Box>
  )
}

export default SponsoredBy

const humanReadableGasTankABI = [
  'function getDelegatedSafes(address delegate) public view returns (address[])',
  'function nonces(address safe, address owner) public view returns (uint16 nonce)',
  'function generateTransferHash(address safe, address token, uint96 amount, uint16 nonce) public view returns (bytes32)',
  'function execTransaction(address _gasTank, address _safe, bytes memory _txData, uint256 _maxFee, bytes memory _feeSignature) public returns (bool success)',
]

export function useGetListOfGasTank() {
  const wallet = useWallet()

  const provider = useWeb3()
  if (!provider) {
    throw new Error('No provider, no GT')
  }

  const gasTankContract = useMemo(
    () => new ethers.Contract(GAS_TANK_MODULE_ADDRESS, humanReadableGasTankABI, provider),
    [provider],
  )

  const { safe } = useSafeInfo()
  const safeModules = safe.modules
  const hasGasTank = useMemo(
    () => safeModules?.some(({ value }) => sameAddress(value, GAS_TANK_MODULE_ADDRESS)),
    [safeModules],
  )
  console.log('delegate::safeModules', safeModules)

  const [gasTankSafes, setGasTankSafes] = useState<Array<string>>([])

  useEffect(() => {
    if (!wallet) {
      return
    }

    const getDelegates = async () => {
      const delegates = await gasTankContract.getDelegatedSafes(wallet.address)
      // FixMe: 🤮
      setGasTankSafes([...(hasGasTank ? [safe.address.value] : []), ...delegates])
    }

    getDelegates()
  }, [gasTankContract, hasGasTank, safe.address.value, wallet])

  console.log('delegate::GT-Safes', gasTankSafes)
  return { gasTankSafes }
}

export const SelectGasTank = ({ onGasTankSelect }: { onGasTankSelect?: (gasTankAddress: string) => void }) => {
  const { gasTankSafes } = useGetListOfGasTank()

  const handleGasTankSelection = (event: SelectChangeEvent) => {
    console.log('selection changed', event.target.value)
    onGasTankSelect?.(event.target.value)
  }

  return (
    <Box className={css.sponsoredBy}>
      <Typography variant="h4" fontWeight={700} mt={3}>
        Select Gas Tank to use
      </Typography>
      <Select
        onChange={handleGasTankSelection}
        sx={{ textAlign: 'right', fontWeight: 700 }}
        IconComponent={ExpandMoreRoundedIcon}
      >
        {gasTankSafes.map((gasTankSafe) => (
          <MenuItem key={gasTankSafe} value={gasTankSafe} sx={{ overflow: 'hidden' }}>
            {gasTankSafe}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
