import { Box, FormControl, FormControlLabel, SvgIcon, Radio, RadioGroup, Typography } from '@mui/material'
import type { Dispatch, SetStateAction, ReactElement, ChangeEvent } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import useWallet from '@/hooks/wallets/useWallet'
import WalletIcon from '@/components/common/WalletIcon'
import GasStation from '@/public/images/common/gas-station.svg'
import RelayerIcon from '@/public/images/common/relayer.svg'
import SponsoredBy from '../SponsoredBy'
import type { RelayResponse } from '@/services/tx/relaying'

import css from './styles.module.css'
import { GasTankAddressesInput } from '@/components/common/AddressBookInput'
import { useAddressResolver } from '@/hooks/useAddressResolver'

export const enum ExecutionMethod {
  RELAY = 'RELAY',
  WALLET = 'WALLET',
  GT_MODULE = 'GAS_TANK_MODULE',
}

export const ExecutionMethodSelector = ({
  executionMethod,
  setExecutionMethod,
  gasTank,
  setGasTank,
  relays,
  noLabel,
  tooltip,
  gasTankEnabled = false,
}: {
  executionMethod: ExecutionMethod
  setExecutionMethod: Dispatch<SetStateAction<ExecutionMethod>>
  gasTank?: string
  setGasTank?: Dispatch<SetStateAction<string | undefined>>
  relays?: RelayResponse
  noLabel?: boolean
  tooltip?: string
  gasTankEnabled?: boolean
}): ReactElement | null => {
  const wallet = useWallet()

  const shouldRelay = executionMethod === ExecutionMethod.RELAY
  const shouldGasTank = executionMethod === ExecutionMethod.GT_MODULE

  const onChooseExecutionMethod = (_: ChangeEvent<HTMLInputElement>, newExecutionMethod: string) => {
    setExecutionMethod(newExecutionMethod as ExecutionMethod)
  }

  type FormData = { gasTank: { address: string; name?: string } }
  const formMethods = useForm<FormData>({
    defaultValues: { gasTank: { address: '' } },
    mode: 'onChange',
  })
  const { handleSubmit, formState, watch, control } = formMethods
  const address = watch('gasTank.address')
  const { name, ens, resolving } = useAddressResolver(address)
  const fallbackName = name || ens
  console.log('fallbackName', fallbackName)

  // TODO: give the user the option to choose GT module to execute the tx
  //  1. User must be able to choose from 'relayer', 'connected wallet', 'GT-module'

  return (
    <Box className={css.container} sx={{ borderRadius: ({ shape }) => `${shape.borderRadius}px` }}>
      <div className={css.method}>
        <FormControl sx={{ display: 'flex' }}>
          {noLabel ? null : (
            <Typography variant="body2" className={css.label}>
              Choose execution method:
            </Typography>
          )}
          <RadioGroup row value={executionMethod} onChange={onChooseExecutionMethod}>
            {gasTankEnabled && (
              <FormControlLabel
                sx={{ flex: 1 }}
                value={ExecutionMethod.GT_MODULE}
                label={
                  <Typography className={css.radioLabel}>
                    <SvgIcon component={GasStation} inheritViewBox fontSize="small" />
                    Gas Tank
                  </Typography>
                }
                control={<Radio />}
              />
            )}
            {!!relays && (
              <FormControlLabel
                sx={{ flex: 1 }}
                value={ExecutionMethod.RELAY}
                label={
                  <Typography className={css.radioLabel}>
                    <SvgIcon component={RelayerIcon} inheritViewBox fontSize="small" />
                    Relayer
                  </Typography>
                }
                control={<Radio />}
              />
            )}
            <FormControlLabel
              sx={{ flex: 1 }}
              value={ExecutionMethod.WALLET}
              label={
                <Typography className={css.radioLabel}>
                  <WalletIcon provider={wallet?.label || ''} width={20} height={20} icon={wallet?.icon} /> Connected
                  wallet
                </Typography>
              }
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </div>

      {shouldRelay && relays ? <SponsoredBy relays={relays} tooltip={tooltip} /> : null}
      {gasTankEnabled && shouldGasTank ? (
        <FormProvider {...formMethods}>
          <form>
            <FormControl fullWidth>
              <GasTankAddressesInput name="gasTank.address" label="GasTank to use" setGasTank={setGasTank} />
            </FormControl>
          </form>
        </FormProvider>
      ) : null}
    </Box>
  )
}
